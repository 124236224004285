import React, { useState } from 'react';

import Header from '../../hooks/common/header';
import { OpenaiKey } from '../../hooks/common/key';
import ChatDataLoader from '../../hooks/audio/chatdataloader';
import ChatDataDisplay from '../../hooks/audio/chatdatadisplay';
import ChatDataDeleter from '../../hooks/audio/chatdatadeleter';
import ChatDatasDeleteButton from '../../hooks/audio/chatdatasdeletebutton';
import ChatDatasDeleter from '../../hooks/audio/chatdatasdeleter';
import ChatSendButton from '../../hooks/audio/chatsendbutton';
import ChatInitButton from '../../hooks/audio/chatinitbutton';
import ChatDisplay from '../../hooks/audio/chatdisplay';

import { ChatAudioData } from '../../@types/data';
import { AudioMessages } from '../../@types/messages';

interface AudioProps {
title: string;
roomType: string;
}

const Audio: React.FC<AudioProps> = ({
title,
roomType
}) => {
const [totalCount, setTotalCount] = useState<number>(0);
const [currentPage, setCurrentPage] = useState<number>(1);
const [totalPage, setTotalPage] = useState<number>(1);
const [userQuery, setUserQuery] = useState<string>('');
const [searchQuery, setSearchQuery] = useState<string>('');
const [chatAudioData, setChatAudioData] = useState<ChatAudioData[]>([]);
const [model, setModel] = useState<string>('gpt-4o-audio-preview');
const [userInput, setUserInput] = useState<string>('');
const [file, setFile] = useState<File | null>(null);
const [base64Audio, setBase64Audio] = useState<string>('');
const [messages, setMessages] = useState<AudioMessages[]>([]);
const [saveResult, setSaveResult] = useState<string>('');
const [deleteTarget, setDeleteTarget] = useState<number | null>(null);
const [deleteTargets, setDeleteTargets] = useState<string>('');
const [deleteResult, setDeleteResult] = useState<string>('');
const [deleteResults, setDeleteResults] = useState<string>('');
const [chatLog, setChatLog] = useState<React.ReactNode>('');
const [voice, setVoice] = useState<string>('nova');
const [audioSrc, setAudioSrc] = useState<string>('');
const [isVoiceSelected, setIsVoiceSelected] = useState<boolean>(false);
const [isSending, setIsSending] = useState<boolean>(false);
const [chatDataLoad, setChatDataLoad] = useState<boolean>(false);
const [apiKey, setApiKey] = useState<string>('');
const [akNo, setAkNo] = useState<number>(0);

return (
<>
<Header
title={title}
/>

<OpenaiKey
apiKey={apiKey}
setApiKey={setApiKey}
setAkNo={setAkNo}
/>

{apiKey && (
<>
<ChatDataLoader
roomType={roomType}
chatDataLoad={chatDataLoad}
setTotalCount={setTotalCount}
currentPage={currentPage}
searchQuery={searchQuery}
setUserQuery={setUserQuery}
deleteResult={deleteResult}
setDeleteResult={setDeleteResult}
deleteResults={deleteResults}
setChatAudioData={setChatAudioData}
akNo={akNo}
/>

<ChatDataDisplay
roomType={roomType}
totalCount={totalCount}
currentPage={currentPage}
setCurrentPage={setCurrentPage}
totalPage={totalPage}
setTotalPage={setTotalPage}
userQuery={userQuery}
setUserQuery={setUserQuery}
setSearchQuery={setSearchQuery}
chatAudioData={chatAudioData}
setDeleteTarget={setDeleteTarget}
isSending={isSending}
setChatDataLoad={setChatDataLoad}
/>

<ChatDataDeleter
roomType={roomType}
setCurrentPage={setCurrentPage}
deleteTarget={deleteTarget}
setDeleteTarget={setDeleteTarget}
setDeleteResult={setDeleteResult}
/>

<ChatDatasDeleteButton
setDeleteTargets={setDeleteTargets}
roomType={roomType}
chatAudioData={chatAudioData}
/>

<ChatDatasDeleter
setCurrentPage={setCurrentPage}
deleteTargets={deleteTargets}
setDeleteTargets={setDeleteTargets}
deleteResults={deleteResults}
setDeleteResults={setDeleteResults}
akNo={akNo}
/>

<ChatSendButton
title={title}
roomType={roomType}
model={model}
setModel={setModel}
userInput={userInput}
setUserInput={setUserInput}
file={file}
setFile={setFile}
base64Audio={base64Audio}
setBase64Audio={setBase64Audio}
messages={messages}
setMessages={setMessages}
voice={voice}
setVoice={setVoice}
isVoiceSelected={isVoiceSelected}
setIsVoiceSelected={setIsVoiceSelected}
isSending={isSending}
setIsSending={setIsSending}
setCurrentPage={setCurrentPage}
setSearchQuery={setSearchQuery}
setChatDataLoad={setChatDataLoad}
/>

<ChatInitButton
setMessages={setMessages}
chatLog={chatLog}
setChatLog={setChatLog}
setVoice={setVoice}
setAudioSrc={setAudioSrc}
setIsVoiceSelected={setIsVoiceSelected}
isSending={isSending}
/>

<ChatDisplay
roomType={roomType}
chatLog={chatLog}
setChatLog={setChatLog}
model={model}
messages={messages}
setMessages={setMessages}
userInput={userInput}
setUserInput={setUserInput}
file={file}
setFile={setFile}
base64Audio={base64Audio}
saveResult={saveResult}
setSaveResult={setSaveResult}
setDeleteResults={setDeleteResults}
voice={voice}
audioSrc={audioSrc}
setAudioSrc={setAudioSrc}
setIsSending={setIsSending}
chatDataLoad={chatDataLoad}
apiKey={apiKey}
akNo={akNo}
/>
</>
)}
</>
);
}

export default Audio;
