import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';

import { axiosErrorHandler } from '../common/error';

interface SoundDisplayProps {
roomType: string;
status: string;
setStatus: React.Dispatch<React.SetStateAction<string>>;
prompt: string;
setPrompt: React.Dispatch<React.SetStateAction<string>>;
setIsSending: React.Dispatch<React.SetStateAction<boolean>>;
xiApiKey: string;
}

const SoundDisplay: React.FC<SoundDisplayProps> = ({
roomType,
status,
setStatus,
prompt,
setPrompt,
setIsSending,
xiApiKey
}) => {
const [audioSrc, setAudioSrc] = useState<string>('');
const [error, setError] = useState<string>('');

useEffect(() => {
const SendRequest = async () => {
setStatus('잠시만 기다려 주세요.');

try {
const response = await axios.post(
`https://api.elevenlabs.io/v1/sound-generation`,
{
text: prompt,
duration_seconds: 20
},
{
headers: {
"Accept": "audio/mpeg",
"Content-Type": "application/json",
"xi-api-key": xiApiKey
},
responseType: 'blob',
}
);

const blob = new Blob([response.data], { type: 'audio/mpeg' });
setStatus('');

if (roomType === 'sounddownload') {
saveAs(blob, 'created-sound.mp3');
} else if (roomType === 'soundplay') {
const audioUrl = URL.createObjectURL(blob);
setAudioSrc(audioUrl);
}
setPrompt('');
setIsSending(false);
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
setStatus('');
setIsSending(false);
}
};

if (prompt) {
SendRequest();
}
}, [roomType, setStatus, prompt, setPrompt, setIsSending, xiApiKey]);

return (
<>
<div aria-live="polite">
{status}
</div>

{roomType === 'soundplay' && audioSrc && <audio controls autoPlay src={audioSrc}></audio>}
{error && (
<div className="error" aria-live="polite">
{error}
</div>
)}
</>
);
}

export default SoundDisplay;
