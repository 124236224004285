import React, { useEffect, useState, FC } from 'react';
import axios from 'axios';

import { axiosErrorHandler } from '../common/error';
import createChatLog from './createchatlog';

import { AudioMessages } from '../../@types/messages';

interface ChatDisplayProps {
roomType: string;
chatLog: React.ReactNode;
setChatLog: React.Dispatch<React.SetStateAction<React.ReactNode>>;
model: string;
messages: AudioMessages[];
setMessages: React.Dispatch<React.SetStateAction<AudioMessages[]>>;
userInput: string;
setUserInput: React.Dispatch<React.SetStateAction<string>>;
file: File | null;
setFile: React.Dispatch<React.SetStateAction<File | null>>;
base64Audio: string;
saveResult: string;
setSaveResult: React.Dispatch<React.SetStateAction<string>>;
setDeleteResults: React.Dispatch<React.SetStateAction<string>>;
voice: string;
audioSrc: string;
setAudioSrc: React.Dispatch<React.SetStateAction<string>>;
setIsSending: React.Dispatch<React.SetStateAction<boolean>>;
chatDataLoad: boolean;
apiKey: string;
akNo: number;
}

const ChatDisplay: FC<ChatDisplayProps> = ({
roomType,
chatLog,
setChatLog,
model,
messages,
setMessages,
userInput,
setUserInput,
file,
setFile,
base64Audio,
saveResult,
setSaveResult,
setDeleteResults,
voice,
audioSrc,
setAudioSrc,
setIsSending,
chatDataLoad,
apiKey,
akNo
}) => {
const [error, setError] = useState<string>('');

useEffect(() => {
const SendRequest = async () => {
setDeleteResults('');
setSaveResult('');
setChatLog('잠시만 기다려 주세요.');

let data = {
model: model,
modalities: ["text", "audio"],
audio: {
voice: voice.toLowerCase(),
format: "wav"
},
messages: messages,
};

const config = {
headers: {
Authorization: `Bearer ${apiKey}`,
'Content-Type': 'application/json',
},
};
let botResponse: string;
let audioId: string;
let audioResponse: string;

try {
const response = await axios.post('https://api.openai.com/v1/chat/completions', data, config);
botResponse = response.data.choices[0].message.audio.transcript;
audioId = response.data.choices[0].message.audio.id;
audioResponse = response.data.choices[0].message.audio.data;
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
setUserInput('');
setFile(null);
setChatLog('');
setIsSending(false);
return;
}

let userAudioFormat: "mp3" | "wav" = 'mp3';
if(file) {
const getFileExtension = (filename: string): string => {
const parts = filename.split('.');
return parts.length > 1 ? parts.pop()!.toLowerCase() : '';
};

const fileExtension = getFileExtension(file.name);
userAudioFormat = fileExtension === 'mp3' ? 'mp3' : 'wav';
}

try {
const saveResponse = await axios.post(`${process.env.REACT_APP_API_URL}/saveChatAudio`, {
userInputText: userInput,
userInputAudioFormat: userAudioFormat,
userInputAudio: base64Audio,
botResponseText: botResponse,
botResponseAudio: audioResponse,
roomType: roomType,
akNo: akNo
}, {
headers: {
'Content-Type': 'application/json'
}
});
const result = saveResponse.data;
setSaveResult(result);
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
setUserInput('');
setChatLog('');
setIsSending(false);
return;
}

setChatLog(createChatLog(userInput, botResponse));
setAudioSrc(`data:audio/wav;base64,${audioResponse}`);
setMessages([
...messages,
{ role: 'assistant',
audio: {
id: audioId
}
}
]);
setUserInput('');
setFile(null);
setIsSending(false);
};

if (messages[messages.length - 1]?.role === 'user' && voice && !chatDataLoad && !error) {
SendRequest();
}
}, [roomType, setChatLog, model, messages, setMessages, userInput, setUserInput, file, setFile, base64Audio, setSaveResult, setDeleteResults, voice, setAudioSrc, setIsSending, chatDataLoad, apiKey, akNo, error, setError]);

return (
<>
{chatLog}
{error && (
<div className="error" aria-live="polite">
{error}
<br /><br />
</div>
)}
{saveResult}
{audioSrc && <audio controls autoPlay src={audioSrc}></audio>}
</>
);
};

export default ChatDisplay;
