import React from 'react';

export const sendHandler = (e: React.KeyboardEvent) => {
if (e.key === 'Enter' && !e.shiftKey) {
e.preventDefault();

const button = document.getElementById('send-button') as HTMLButtonElement;

if (button) {
button.click();
}
}
}

export const searchHandler = (e: React.KeyboardEvent) => {
if (e.key === 'Enter' && !e.shiftKey) {
e.preventDefault();

const button = document.getElementById('search-button') as HTMLButtonElement;

if (button) {
button.click();
}
}
}
