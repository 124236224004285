import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { axiosErrorHandler } from '../common/error';

import { TransData } from '../../@types/data';
import { Item } from '../../@types/item';

interface TransDataLoaderProps {
roomType: string;
deleteResult: string;
setDeleteResult: React.Dispatch<React.SetStateAction<string>>;
deleteResults: string;
transDataLoad: boolean;
setTotalCount: React.Dispatch<React.SetStateAction<number>>;
currentPage: number;
searchQuery: string;
setUserQuery: React.Dispatch<React.SetStateAction<string>>;
setTransData: React.Dispatch<React.SetStateAction<TransData[]>>;
akNo: number;
}

const TransDataLoader: React.FC<TransDataLoaderProps> = ({
roomType,
deleteResult,
setDeleteResult,
deleteResults,
transDataLoad,
setTotalCount,
currentPage,
searchQuery,
setUserQuery,
setTransData,
akNo
}) => {
const [loading, setLoading] = useState<boolean>(true);
const [error, setError] = useState<string>('');

useEffect(() => {
const fetchData = async () => {

try {
const response = await axios.post(`${process.env.REACT_APP_API_URL}/getTransData`,
{
roomType: roomType,
akNo: akNo,
startRow: 10 * (currentPage - 1),
rowPerPage: 10,
searchQuery: searchQuery
},
{
headers: {
'Content-Type': 'application/json',
},
});
const data = response.data;
const formattedData: TransData[] = data.transs.map((item: Item) => ({
id: item.t_no,
request: item.request,
answerLines: item.answer.replace(/\.\s/g, ".\n").replace(/\?\s/g, "?\n").split('\n'),
}));

setTotalCount(response.data.totalCount);
setTransData(formattedData);
setUserQuery('');
setDeleteResult('');
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
} finally {
setLoading(false);
}
};

fetchData();
}, [roomType, deleteResult, setDeleteResult, deleteResults, transDataLoad, setTotalCount, currentPage, searchQuery, setUserQuery, setTransData, akNo, setError]);

if (loading) {
return <div aria-live="polite">Loading...</div>;
}

if (error) {
return (
<div className="error" aria-live="polite">
{error}
</div>
);
}

return null;
};

export default TransDataLoader;
