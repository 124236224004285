import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { axiosErrorHandler } from '../common/error';

import { ChatData } from '../../@types/data';
import { Item } from '../../@types/item';

interface ChatDataLoaderProps {
roomType: string;
deleteResult: string;
setDeleteResult: React.Dispatch<React.SetStateAction<string>>;
deleteResults: string;
chatDataLoad: boolean;
setTotalCount: React.Dispatch<React.SetStateAction<number>>;
currentPage: number;
searchQuery: string;
setUserQuery: React.Dispatch<React.SetStateAction<string>>;
setChatData: React.Dispatch<React.SetStateAction<ChatData[]>>;
akNo: number;
}

const ChatDataLoader: React.FC<ChatDataLoaderProps> = ({
roomType,
deleteResult,
setDeleteResult,
deleteResults,
chatDataLoad,
setTotalCount,
currentPage,
searchQuery,
setUserQuery,
setChatData,
akNo
}) => {
const [loading, setLoading] = useState<boolean>(true);
const [error, setError] = useState<string>('');

useEffect(() => {
const fetchData = async () => {

try {
const response = await axios.post(
`${process.env.REACT_APP_API_URL}/getChatData`,
{
roomType: roomType,
akNo: akNo,
startRow: 10 * (currentPage - 1),
rowPerPage: 10,
searchQuery: searchQuery
},
{
headers: {
'Content-Type': 'application/json',
},
}
);
const data = response.data;
const formattedData: ChatData[] = data.chats.map((item: Item) => ({
id: item.c_no,
request: item.request,
answer: item.answer,
requestLines: item.request.split('\n'),
answerLines: item.answer.split('\n').map(line => line.replace(/\\(['"])/g, '$1')),
}));

setTotalCount(response.data.totalCount);
setChatData(formattedData);
setUserQuery('');
setDeleteResult('');
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
} finally {
setLoading(false);
}
};

fetchData();
}, [roomType, deleteResult, setDeleteResult, deleteResults, chatDataLoad, setTotalCount, currentPage, searchQuery, setUserQuery, setChatData, akNo, setError]);

if (loading) {
return <div aria-live="polite">Loading...</div>;
}

if (error) {
return (
<div className="error" aria-live="polite">
{error}
</div>
);
}

return null;
};

export default ChatDataLoader;
