import React from 'react';

import { Messages } from '../../@types/messages';

interface ChatInitButtonProps {
setMessages: React.Dispatch<React.SetStateAction<Messages[]>>;
chatLog: React.ReactNode;
setChatLog: React.Dispatch<React.SetStateAction<React.ReactNode>>;
setUsed: React.Dispatch<React.SetStateAction<React.ReactNode>>;
setSaveResult: React.Dispatch<React.SetStateAction<string>>;
isVoiceEnabled: boolean;
setIsVoiceEnabled: React.Dispatch<React.SetStateAction<boolean>>;
setAudioSrc: React.Dispatch<React.SetStateAction<string>>;
setChatDataLoad: React.Dispatch<React.SetStateAction<boolean>>;
isSending: boolean;
}

const ChatInitButton: React.FC<ChatInitButtonProps> = ({
setMessages,
chatLog,
setChatLog,
setUsed,
setSaveResult,
isVoiceEnabled,
setIsVoiceEnabled,
setAudioSrc,
setChatDataLoad,
isSending
}) => {
const initButtonHandler = () => {
if (chatLog) {
setMessages([]);
setChatLog('');
setUsed('');
setSaveResult('');
setChatDataLoad(true);

if (isVoiceEnabled) {
setAudioSrc('');
setIsVoiceEnabled(false);
}

alert('지금까지의 모든 채팅 기록을 삭제했습니다. 이제 다시 새로운 주제로 채팅을 시작하실 수 있습니다.');
} else {
alert('아직 채팅 기록이 없습니다.');
}
};

return (
<>
<button onClick={initButtonHandler} disabled={isSending}>
새 채팅
</button>
<br/><br/>
</>
);
};

export default ChatInitButton;
