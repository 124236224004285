import React, { useState } from 'react';

import { sendHandler } from '../common/keydownhandler';

interface RequestSendButtonProps {
title: string;
roomType: string;
text: string;
setText: React.Dispatch<React.SetStateAction<string>>;
setPrompt: React.Dispatch<React.SetStateAction<string>>;
file: File | null;
setFile: React.Dispatch<React.SetStateAction<File | null>>;
setSource: React.Dispatch<React.SetStateAction<File | null>>;
setOutput: React.Dispatch<React.SetStateAction<React.ReactNode>>;
isSending: boolean;
setIsSending: React.Dispatch<React.SetStateAction<boolean>>;
}

const RequestSendButton: React.FC<RequestSendButtonProps> = ({
title,
roomType,
text,
setText,
setPrompt,
file,
setFile,
setSource,
setOutput,
isSending,
setIsSending
}) => {
const [inputKey, setInputKey] = useState<number>(Date.now());

const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
const selectedFile = event.target.files ? event.target.files[0] : null;
if (selectedFile) {
const allowedExtensions = /(\.png)$/i;
if (!allowedExtensions.exec(selectedFile.name)) {
alert('허용되는 파일 형식은 .png입니다.');
setInputKey(Date.now());
return;
}
setFile(selectedFile);
setOutput('');
}
};

const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
e.preventDefault();

if (!file) {
alert("변형할 파일을 선택하세요.");
return;
}

setIsSending(true);
setSource(file);
setInputKey(Date.now());
}

const sendButtonHandler = () => {
if (!text.trim()) {
alert('생성할 이미지에 대한 내용을 입력하세요.');
return;
}

setIsSending(true);
setPrompt(text);
};

return (
<>
<h1>{title}</h1>
{roomType === 'imagecreation' && (
<>
<p>생성할 이미지에 대한 내용을 입력하세요.</p>
<p>생성할 이미지에 대한 내용</p>
<textarea
placeholder="생성할 이미지에 대한 내용"
value={text}
onChange={e => setText(e.target.value)}
onKeyDown={sendHandler}>
</textarea>
<button id="send-button" onClick={sendButtonHandler} disabled={isSending}>이미지 생성</button>
</>
)}
{roomType === 'imagevariation' && (
<>
<p>변형할 이미지 파일을 선택하세요.</p>
<form onSubmit={submitHandler}>
<input type="file" key={inputKey} onChange={fileChangeHandler} />
<p>{file ? file.name : "파일이 선택되지 않았습니다."}</p>
<br/><br/>
<button type="submit" disabled={isSending}>이미지 변형</button>
</form>
</>
)}
<br/>
</>
);
}

export default RequestSendButton;
