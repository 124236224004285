import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { axiosErrorHandler } from '../common/error';

import { ChatAudioData } from '../../@types/data';
import { Item } from '../../@types/item';

interface ChatDataLoaderProps {
roomType: string;
chatDataLoad: boolean;
setTotalCount: React.Dispatch<React.SetStateAction<number>>;
currentPage: number;
searchQuery: string;
setUserQuery: React.Dispatch<React.SetStateAction<string>>;
deleteResult: string;
setDeleteResult: React.Dispatch<React.SetStateAction<string>>;
deleteResults: string;
setChatAudioData: React.Dispatch<React.SetStateAction<ChatAudioData[]>>;
akNo: number;
}

const ChatDataLoader: React.FC<ChatDataLoaderProps> = ({
roomType,
chatDataLoad,
setTotalCount,
currentPage,
searchQuery,
setUserQuery,
deleteResult,
setDeleteResult,
deleteResults,
setChatAudioData,
akNo
}) => {
const [loading, setLoading] = useState<boolean>(true);
const [error, setError] = useState<string>('');

useEffect(() => {
const fetchData = async () => {
try {
const response = await axios.post(
`${process.env.REACT_APP_API_URL}/getChatAudioData`,
{
roomType: roomType,
akNo: akNo,
startRow: 10 * (currentPage - 1),
rowPerPage: 10,
searchQuery: searchQuery
},
{
headers: {
'Content-Type': 'application/json',
},
}
);
const data = response.data;
const formattedData: ChatAudioData[] = data.chats.map((item: Item) => ({
id: item.ca_no,
requestTextLines: item.request_text.split('\n'),
requestAudioFormat: item.request_audio_format,
requestAudio: item.request_audio,
answerTextLines: item.answer_text.split('\n').map(line => line.replace(/\\(['"])/g, '$1')),
answerAudio: item.answer_audio
}));

setTotalCount(response.data.totalCount);
setChatAudioData(formattedData);
setUserQuery('');
setDeleteResult('');
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
} finally {
setLoading(false);
}
};

fetchData();
}, [roomType, chatDataLoad, setTotalCount, currentPage, searchQuery, setUserQuery, deleteResult, setDeleteResult, deleteResults, setChatAudioData, akNo, setError]);

if (loading) {
return <div aria-live="polite">Loading...</div>;
}

if (error) {
return (
<div className="error" aria-live="polite">
{error}
</div>
);
}

return null;
};

export default ChatDataLoader;
