import React, { useState } from 'react';

import { searchHandler } from '../common/keydownhandler';

import { TransData } from '../../@types/data';
import result from '../../assets/sound/result.wav';
import OnePagePrior from '../../assets/sound/OnePagePrior.wav';
import OnePageNext from '../../assets/sound/OnePageNext.wav';

interface TransDataDisplayProps {
roomType: string;
totalCount: number;
currentPage: number;
setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
totalPage: number;
setTotalPage: React.Dispatch<React.SetStateAction<number>>;
userQuery: string;
setUserQuery: React.Dispatch<React.SetStateAction<string>>;
setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
transData: TransData[];
setTransDataLoad: React.Dispatch<React.SetStateAction<boolean>>;
setDeleteTarget: React.Dispatch<React.SetStateAction<number | null>>;
}

const TransDataDisplay: React.FC<TransDataDisplayProps> = ({
roomType,
totalCount,
currentPage,
setCurrentPage,
totalPage,
setTotalPage,
userQuery,
setUserQuery,
setSearchQuery,
transData,
setTransDataLoad,
setDeleteTarget
}) => {
const [visibleAnswerId, setVisibleAnswerId] = useState<number | null>(null);

const searchButtonHandler = () => {
setCurrentPage(1);
setSearchQuery(userQuery);
const audio = new Audio(result);
audio.play();
};

const previousPageButtonHandler = () => {
setCurrentPage(currentPage - 1)
const audio = new Audio(OnePagePrior);
audio.play();
};

const nextPageButtonHandler = () => {
setCurrentPage(currentPage + 1)
const audio = new Audio(OnePageNext);
audio.play();
};

const deleteDataHandler = (id: number) => {
setDeleteTarget(id);
};

const toggleAnswerVisibility = (id: number) => {
if (visibleAnswerId === id) {
setVisibleAnswerId(null); // 이미 보이는 답변을 클릭하면 숨깁니다.
} else {
setVisibleAnswerId(id); // 다른 답변을 클릭하면 해당 답변을 보여줍니다.
}
};

return (
<>
{transData.length > 0 && (
<>
{setTotalPage(Math.ceil(totalCount / 10))}
</>
)}

{transData.length === 0 && (
<>
{setTotalPage(1)}
</>
)}

<p>검색어를 입력하세요.</p>
<p>검색어</p>
<input
type="search"
placeholder="검색어"
value={userQuery}
onChange={e => setUserQuery(e.target.value)}
onKeyDown={searchHandler}
required/>
<br/><br/>
<button id="search-button" onClick={searchButtonHandler}>검색</button>

<h1>{roomType === 'transcript' ? "기존 변환 기록" : "기존 번역 기록"}</h1>
<br />
<p>총 {totalCount} 건</p>
<br />

{transData.map((item) => (
<React.Fragment key={item.id}>
{/* 요청 부분에 클릭 이벤트 핸들러를 추가하여 답변의 표시 여부를 토글하도록 합니다. */}
<h2 onClick={() => toggleAnswerVisibility(item.id)} style={{ cursor: 'pointer' }}>
{item.request}
</h2>
<br />
{/* 현재 보이는 답변의 ID와 아이템의 ID가 일치할 경우에만 답변을 표시합니다. */}
{visibleAnswerId === item.id &&
item.answerLines.map((line, index) => (
<React.Fragment key={index}>
{line}
<br />
</React.Fragment>
))}
<br /><br />
<button onClick={() => deleteDataHandler(item.id)}>삭제</button>
<br /><br /><br />
</React.Fragment>
))}

<p>{currentPage}/{totalPage} 페이지</p>
<br/>
<button
onClick={() => previousPageButtonHandler()}
disabled={currentPage === 1}>
이전
</button>
<br/><br/>
<button
onClick={() => nextPageButtonHandler()}
disabled={currentPage === totalPage}>
다음
</button>
<br/><br/>

{setTransDataLoad(false)}
</>
);
};

export default TransDataDisplay;
