import React, { useState } from 'react';

import { models } from './model';
import { sendHandler } from '../common/keydownhandler';

import { VisionMessages } from '../../@types/messages';

interface ChatSendButtonProps {
title: string;
roomType: string;
model: string;
setModel: React.Dispatch<React.SetStateAction<string>>;
userInput: string;
setUserInput: React.Dispatch<React.SetStateAction<string>>;
file: File | null;
setFile: React.Dispatch<React.SetStateAction<File | null>>;
messages: VisionMessages[];
setMessages: React.Dispatch<React.SetStateAction<VisionMessages[]>>;
isVoiceEnabled: boolean;
setIsVoiceEnabled: React.Dispatch<React.SetStateAction<boolean>>;
isSending: boolean;
setIsSending: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChatSendButton: React.FC<ChatSendButtonProps> = ({
title,
roomType,
model,
setModel,
userInput,
setUserInput,
file,
setFile,
messages,
setMessages,
isVoiceEnabled,
setIsVoiceEnabled,
isSending,
setIsSending
}) => {
const [base64Image, setBase64Image] = useState<string>('');
const [inputKey, setInputKey] = useState<number>(Date.now());

const encodeImage = (selectedFile: File) => {
const reader = new FileReader();

reader.onloadend = () => {
if (reader.result) {
setBase64Image(reader.result as string);
}
};

reader.readAsDataURL(selectedFile);
};

const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
const selectedFile = event.target.files ? event.target.files[0] : null;

if (selectedFile) {
if (selectedFile.type === "image/jpeg" || "image/png") {
setFile(selectedFile);
encodeImage(selectedFile);
} else {
alert('jpg 파일만 업로드 가능합니다.'); // 경고 메시지 표시
setInputKey(Date.now());
}
}
};

const sendButtonHandler = () => {
if (!userInput.trim()) {
alert('입력한 내용이 없습니다. 요청할 내용을 입력하세요.');
return;
}

setIsSending(true);
if(file) {
setMessages([...messages,
{ role: 'user',
content: [
{ type: "text", text: userInput },
{
type: "image_url",
image_url: {
url: base64Image
}
}
]
}
]);
} else {
setMessages([...messages,
{ role: 'user',
content: [
{ type: "text", text: userInput }
]
}
]
);
}
setInputKey(Date.now());
};

return (
<>
<h1>{title}</h1>
{roomType === 'chatvision' && (
<>
<p>gpt 모델을 선택하세요.</p>
<p>gpt 모델 선택:</p>
<select value={model} onChange={(event) => setModel(event.target.value)} disabled={isSending}>
{models.map((modelOption) => (
<option key={modelOption.value} value={modelOption.value}>
{modelOption.name}
</option>
))}
</select>
<br/><br/>
<p>요청할 내용을 입력하세요.</p>
<p>요청할 내용</p>
<textarea
placeholder="요청할 내용"
value={userInput}
onChange={e => setUserInput(e.target.value)}
onKeyDown={sendHandler}
required>
</textarea>
<br/><br/>
<p>채팅에 추가할 이미지 파일을 선택하세요.</p>
<p>채팅에 추가할 이미지 파일</p>
<input type="file" key={inputKey} onChange={fileChangeHandler} />
<p>{file ? file.name : "파일이 선택되지 않았습니다."}</p>
<br/><br/>
<input type="checkbox" checked={isVoiceEnabled} onChange={e => setIsVoiceEnabled(e.target.checked)} disabled={isSending} placeholder="음성 출력 사용" />
<br/><br/>
<button id="send-button" onClick={sendButtonHandler} disabled={isSending}>전송</button>
</>
)}
</>
);
};

export default ChatSendButton;
