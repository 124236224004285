import { useEffect, useState, FC } from 'react';
import axios from 'axios';

import { axiosErrorHandler } from '../common/error';

interface ChatDataDeleterProps {
roomType: string;
setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
deleteTarget: number | null;
setDeleteTarget: React.Dispatch<React.SetStateAction<number | null>>;
setDeleteResult: React.Dispatch<React.SetStateAction<string>>;
}

const ChatDataDeleter: FC<ChatDataDeleterProps> = ({
roomType,
setCurrentPage,
deleteTarget,
setDeleteTarget,
setDeleteResult
}) => {
const [error, setError] = useState<string>('');

useEffect(() => {
const deleteData = async () => {
try {
const response = await axios.post(`${process.env.REACT_APP_API_URL}/deleteChatAudioData`, { id: deleteTarget }, {
headers: {
'Content-Type': 'application/json',
},
});
const deleteResponse = response.data;
setCurrentPage(1);
setDeleteResult(deleteResponse);
alert(deleteResponse);
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
}
};

if (deleteTarget) {
deleteData().then(() => {
setDeleteTarget(null);
});
}
}, [roomType, setCurrentPage, deleteTarget, setDeleteTarget, setDeleteResult, setError]);

return (
<>
{error && (
<div className="error" aria-live="polite">
{error}
<br /><br />
</div>
)}
</>
);
};

export default ChatDataDeleter;
