import React, { useState } from 'react';

import { models } from './model';
import { voices } from './voice';
import { sendHandler } from '../common/keydownhandler';

import { AudioMessages } from '../../@types/messages';

interface ChatSendButtonProps {
title: string;
roomType: string;
model: string;
setModel: React.Dispatch<React.SetStateAction<string>>;
userInput: string;
setUserInput: React.Dispatch<React.SetStateAction<string>>;
file: File | null;
setFile: React.Dispatch<React.SetStateAction<File | null>>;
base64Audio: string;
setBase64Audio: React.Dispatch<React.SetStateAction<string>>;
messages: AudioMessages[];
setMessages: React.Dispatch<React.SetStateAction<AudioMessages[]>>;
voice: string;
setVoice: React.Dispatch<React.SetStateAction<string>>;
isVoiceSelected: boolean;
setIsVoiceSelected: React.Dispatch<React.SetStateAction<boolean>>;
isSending: boolean;
setIsSending: React.Dispatch<React.SetStateAction<boolean>>;
setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
setChatDataLoad: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChatSendButton: React.FC<ChatSendButtonProps> = ({
title,
roomType,
model,
setModel,
userInput,
setUserInput,
file,
setFile,
base64Audio,
setBase64Audio,
messages,
setMessages,
voice,
setVoice,
isVoiceSelected,
setIsVoiceSelected,
isSending,
setIsSending,
setCurrentPage,
setSearchQuery,
setChatDataLoad,
}) => {
const [inputKey, setInputKey] = useState<number>(Date.now());

const encodeAudio = (selectedFile: File) => {
const reader = new FileReader();

reader.onloadend = () => {
if (reader.result) {
const base64String = (reader.result as string).split(',')[1];
setBase64Audio(base64String);
}
};

reader.readAsDataURL(selectedFile);
};

const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
const selectedFile = event.target.files ? event.target.files[0] : null;

if (selectedFile) {
const allowedTypes = [
"audio/mpeg", // MP3
"audio/wav", // WAV
"audio/x-wav", // WAV (일부 브라우저에서 사용)
"audio/wave", // WAV (일부 브라우저에서 사용)
"audio/vnd.wave" // WAV (추가적인 변형)
];

if (allowedTypes.includes(selectedFile.type)) {
setFile(selectedFile);
encodeAudio(selectedFile);
} else {
alert('MP3 또는 WAV 파일만 업로드 가능합니다.');
setInputKey(Date.now());
}
}
};

const sendButtonHandler = () => {
if (!userInput.trim()) {
alert('입력한 내용이 없습니다. 요청할 내용을 입력하세요.');
return;
}

setIsSending(true);
setCurrentPage(1);
setSearchQuery('');
setIsVoiceSelected(true);
setChatDataLoad(true);
if(file) {
const getFileExtension = (filename: string): string => {
const parts = filename.split('.');
return parts.length > 1 ? parts.pop()!.toLowerCase() : '';
};

const fileExtension = getFileExtension(file.name);
const format: "mp3" | "wav" = fileExtension === 'mp3' ? 'mp3' : 'wav';

setMessages([...messages,
{ role: 'user',
content: [
{ type: "text", text: userInput },
{
type: "input_audio",
input_audio: {
data: base64Audio,
format: format
}
}
]
}
]);
} else {
setMessages([...messages, { role: 'user', content: userInput }]);
}
setInputKey(Date.now());
};

return (
<>
<h1>{title}</h1>

{roomType === 'chataudio' && (
<>
<p>gpt 모델을 선택하세요.</p>
<p>gpt 모델 선택:</p>
<select value={model} onChange={(event) => setModel(event.target.value)} disabled={isSending}>
{models.map((modelOption) => (
<option key={modelOption.value} value={modelOption.value}>
{modelOption.name}
</option>
))}
</select>
<br/><br/>
<p>요청할 내용을 입력하세요.</p>
<p>요청할 내용</p>
<textarea
placeholder="요청할 내용"
value={userInput}
onChange={e => setUserInput(e.target.value)}
onKeyDown={sendHandler}
required>
</textarea>
<br/><br/>
<p>채팅에 추가할 오디오 파일을 선택하세요.</p>
<p>채팅에 추가할 오디오 파일</p>
<input type="file" key={inputKey} onChange={fileChangeHandler} />
<p>{file ? file.name : "파일이 선택되지 않았습니다."}</p>
<br/><br/>
{!isVoiceSelected && (
<>
<p>음성을 선택하세요.</p>
<p>음성 선택:</p>
{voices.map((voiceOption) => (
<label key={voiceOption.value}>
<input
type="radio"
name="voice"
value={voiceOption.value}
checked={voiceOption.value === voice}
onChange={(event) => setVoice(event.target.value)}
disabled={isSending}
/>
{voiceOption.name}
</label>
))}
<br /><br />
</>
)}
<button id="send-button" onClick={sendButtonHandler} disabled={isSending}>전송</button>
</>
)}
</>
);
};

export default ChatSendButton;
