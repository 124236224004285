import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';

import { axiosErrorHandler } from '../common/error';

interface TtsDisplayProps {
roomType: string;
status: string;
setStatus: React.Dispatch<React.SetStateAction<string>>;
prompt: string;
setPrompt: React.Dispatch<React.SetStateAction<string>>;
voice: string;
setIsSending: React.Dispatch<React.SetStateAction<boolean>>;
apiKey: string;
}

const TtsDisplay: React.FC<TtsDisplayProps> = ({
roomType,
status,
setStatus,
prompt,
setPrompt,
voice,
setIsSending,
apiKey
}) => {
const [audioSrc, setAudioSrc] = useState<string>('');
const [error, setError] = useState<string>('');

useEffect(() => {
const SendRequest = async () => {
setStatus('잠시만 기다려 주세요.');

try {
const response = await axios.post(
'https://api.openai.com/v1/audio/speech',
{
model: "tts-1-hd",
voice: voice.toLowerCase(),
input: prompt
},
{
headers: {
'Authorization': `Bearer ${apiKey}`,
'Content-Type': 'application/json',
},
responseType: 'blob',
}
);

const blob = new Blob([response.data], { type: 'audio/mpeg' });
setStatus('');

if (roomType === 'ttsdownload') {
saveAs(blob, 'converted-audio.mp3');
} else if (roomType === 'ttsplay') {
const audioUrl = URL.createObjectURL(blob);
setAudioSrc(audioUrl);
}
setPrompt('');
setIsSending(false);
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
setStatus('');
setIsSending(false);
}
};

if (prompt) {
SendRequest();
}
}, [roomType, setStatus, prompt, setPrompt, voice, setIsSending, apiKey, setError]);

return (
<>
<div aria-live="polite">
{status}
</div>

{roomType === 'ttsplay' && audioSrc && <audio controls autoPlay src={audioSrc}></audio>}
{error && (
<div className="error" aria-live="polite">
{error}
<br/><br/>
</div>
)}
</>
);
};

export default TtsDisplay;
