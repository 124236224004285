import React, { useEffect, useState, FC } from 'react';
import axios from 'axios';

import { axiosErrorHandler } from '../common/error';
import createChatLog from './createchatlog';
import createUsed from './createused';

import { VisionMessages } from '../../@types/messages';

interface ChatDisplayProps {
roomType: string;
chatLog: React.ReactNode;
setChatLog: React.Dispatch<React.SetStateAction<React.ReactNode>>;
used: React.ReactNode;
setUsed: React.Dispatch<React.SetStateAction<React.ReactNode>>;
model: string;
messages: VisionMessages[];
setMessages: React.Dispatch<React.SetStateAction<VisionMessages[]>>;
userInput: string;
setUserInput: React.Dispatch<React.SetStateAction<string>>;
setFile: React.Dispatch<React.SetStateAction<File | null>>;
isVoiceEnabled: boolean;
audioSrc: string;
setAudioSrc: React.Dispatch<React.SetStateAction<string>>;
setIsSending: React.Dispatch<React.SetStateAction<boolean>>;
apiKey: string;
}

const ChatDisplay: FC<ChatDisplayProps> = ({
roomType,
chatLog,
setChatLog,
used,
setUsed,
model,
messages,
setMessages,
userInput,
setUserInput,
setFile,
isVoiceEnabled,
audioSrc,
setAudioSrc,
setIsSending,
apiKey
}) => {
const [error, setError] = useState<string>('');

useEffect(() => {
const SendRequest = async () => {
setChatLog('잠시만 기다려 주세요.');

let data = {
model: model,
max_tokens: 2048,
messages: messages,
};

const config = {
headers: {
Authorization: `Bearer ${apiKey}`,
'Content-Type': 'application/json',
},
};
let botResponse: string;
let promptTokens: number;
let completionTokens: number;
let totalTokens: number;
let reasoningTokens: number;

try {
const response = await axios.post('https://api.openai.com/v1/chat/completions', data, config);
botResponse = response.data.choices[0].message.content;
promptTokens = response.data.usage.prompt_tokens;
completionTokens = response.data.usage.completion_tokens;
totalTokens = response.data.usage.total_tokens;
reasoningTokens = response.data.usage.completion_tokens_details.reasoning_tokens;
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
setUserInput('');
setChatLog('');
setIsSending(false);
return;
}

setChatLog(createChatLog(roomType, userInput, botResponse));
setUsed(createUsed(promptTokens, completionTokens, totalTokens, reasoningTokens));
setMessages([...messages,
{ role: 'assistant',
content: [
{ type: "text",
text: botResponse
}
]
}
]
);
setUserInput('');
setFile(null);
setIsSending(false);
if (!isVoiceEnabled) {
return;
}

try {
const response = await axios.post(
'https://api.openai.com/v1/audio/speech',
{
model: "tts-1-hd",
voice: "nova",
input: botResponse
},
{
headers: {
'Authorization': `Bearer ${apiKey}`,
'Content-Type': 'application/json',
},
responseType: 'blob',
}
);

const audioUrl = URL.createObjectURL(new Blob([response.data], { type: 'audio/mpeg' }));
setAudioSrc(audioUrl);
setFile(null);
setIsSending(false);
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
setFile(null);
setIsSending(false);
}
};

if (messages[messages.length - 1]?.role === 'user' && !error) {
SendRequest();
}
}, [roomType, setChatLog, setUsed, model, messages, setMessages, userInput, setUserInput, setFile, isVoiceEnabled, setAudioSrc, setIsSending, apiKey, error, setError]);

return (
<>
<div aria-live="polite">
{chatLog}
</div>
{used}
{error && (
<div className="error" aria-live="polite">
{error}
<br /><br />
</div>
)}
{isVoiceEnabled && audioSrc && <audio controls autoPlay src={audioSrc}></audio>}
</>
);
};

export default ChatDisplay;
