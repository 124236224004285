import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { axiosErrorHandler } from '../common/error';
import createOutput from './createoutput';

import { ImageBotResponse } from '../../@types/botresponse';

interface ImageDisplayProps {
roomType: string;
status: string;
setStatus: React.Dispatch<React.SetStateAction<string>>;
setText: React.Dispatch<React.SetStateAction<string>>;
prompt: string;
setPrompt: React.Dispatch<React.SetStateAction<string>>;
setFile: React.Dispatch<React.SetStateAction<File | null>>;
source: File | null;
setSource: React.Dispatch<React.SetStateAction<File | null>>;
output: React.ReactNode;
setOutput: React.Dispatch<React.SetStateAction<React.ReactNode>>;
setIsSending: React.Dispatch<React.SetStateAction<boolean>>;
apiKey: string;
}

const ImageDisplay: React.FC<ImageDisplayProps> = ({
roomType,
status,
setStatus,
setText,
prompt,
setPrompt,
setFile,
source,
setSource,
output,
setOutput,
setIsSending,
apiKey
}) => {
const [error, setError] = useState<string>('');

useEffect(() => {
const sendRequest = async () => {
setStatus('잠시만 기다려 주세요.');
setOutput('');
let botResponse: ImageBotResponse;
try {
let endpoint = '';
let formData: any = new FormData();
if (roomType === 'imagecreation') {
endpoint = 'images/generations';
formData.append('model', 'dall-e-3');
formData.append('prompt', prompt);
formData.append('style', 'natural');
formData.append('quality', 'hd');
} else if (roomType === 'imagevariation') {
endpoint = 'images/variations';
formData.append('image', source);
formData.append('model', 'dall-e-2');
formData.append('n', 3);
}

let headers: any = {};
if (roomType === 'imagecreation') {
headers = {
'Authorization': `Bearer ${apiKey}`,
'Content-Type': 'application/json',
};
} else if (roomType === 'imagevariation') {
headers = {
'Authorization': `Bearer ${apiKey}`,
};
}

const response = await axios.post(`https://api.openai.com/v1/${endpoint}`, formData, { headers });

botResponse = response.data;
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
resetState();
setIsSending(false);
return;
}

setStatus('');
setOutput(createOutput(prompt, botResponse, roomType));
resetState();
};

const resetState = () => {
setText('');
setStatus('');
setPrompt('');
setFile(null);
setSource(null);
setIsSending(false);
}

if (prompt || source) {
sendRequest();
}
}, [roomType, setStatus, setText, prompt, setPrompt, setFile, source, setSource, setOutput, setIsSending, apiKey, setError]);

return (
<>
<div aria-live="polite">
{status}
{output}
</div>
{error && (
<div className="error" aria-live="polite">
{error}
</div>
)}
</>
);
};

export default ImageDisplay;
