import React from 'react';

import { models } from './model';
import { sendHandler } from '../common/keydownhandler';

import { Messages } from '../../@types/messages';

interface ChatSendButtonProps {
title: string;
roomType: string;
model: string;
setModel: React.Dispatch<React.SetStateAction<string>>;
userInput: string;
setUserInput: React.Dispatch<React.SetStateAction<string>>;
messages: Messages[];
setMessages: React.Dispatch<React.SetStateAction<Messages[]>>;
isVoiceEnabled: boolean;
setIsVoiceEnabled: React.Dispatch<React.SetStateAction<boolean>>;
isSending: boolean;
setIsSending: React.Dispatch<React.SetStateAction<boolean>>;
setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
setChatDataLoad: React.Dispatch<React.SetStateAction<boolean>>;
apiKey: string;
}

const ChatSendButton: React.FC<ChatSendButtonProps> = ({
title,
roomType,
model,
setModel,
userInput,
setUserInput,
messages,
setMessages,
isVoiceEnabled,
setIsVoiceEnabled,
isSending,
setIsSending,
setCurrentPage,
setSearchQuery,
setChatDataLoad,
apiKey
}) => {
const sendButtonHandler = () => {
if (!userInput.trim()) {
alert('입력한 내용이 없습니다. 요청할 내용을 입력하세요.');
return;
}

setIsSending(true);
setCurrentPage(1);
setSearchQuery('');
setChatDataLoad(true);
setMessages([...messages, { role: 'user', content: userInput }]);
};

return (
<>
<h1>{title}</h1>

{roomType === 'chato1' && (
<>
<p>o1 모델을 선택하세요.</p>
<p>o1 모델 선택:</p>
<select value={model} onChange={(event) => setModel(event.target.value)} disabled={isSending}>
{models.map((modelOption) => (
<option key={modelOption.value} value={modelOption.value}>
{modelOption.name}
</option>
))}
</select>
<br/><br/>
<p>요청할 내용을 입력하세요.</p>
<p>요청할 내용</p>
<textarea
placeholder="요청할 내용"
value={userInput}
onChange={e => setUserInput(e.target.value)}
onKeyDown={sendHandler}
required>
</textarea>
<br/><br/>
<input type="checkbox" checked={isVoiceEnabled} onChange={e => setIsVoiceEnabled(e.target.checked)} disabled={isSending} placeholder="음성 출력 사용" />
<br/><br/>
<button id="send-button" onClick={sendButtonHandler} disabled={isSending}>전송</button>
</>
)}
</>
);
};

export default ChatSendButton;
