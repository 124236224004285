import React, { useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import Header from '../../hooks/common/header';
import { axiosErrorHandler } from '../../hooks/common/error';
import { encodeToBase64 } from '../../hooks/common/encode';

interface AuthProps {
title: string;
}

const Auth: React.FC<AuthProps> = ({
title
}) => {
const [uuid] = useState(() => {
const existingUuid = localStorage.getItem('uuid');
if (!existingUuid) {
const newUuid = uuidv4();
localStorage.setItem('uuid', newUuid); // 새로 생성된 uuid를 로컬 스토리지에 저장
return newUuid;
}
return existingUuid;
});
const [apiKey, setApiKey] = useState('');
const [xiApiKey, setXiApiKey] = useState('');
const [grokApiKey, setGrokApiKey] = useState('');
const [saveApiKeyResult, setSaveApiKeyResult] = useState('');
const [saveXiApiKeyResult, setSaveXiApiKeyResult] = useState('');
const [saveGrokApiKeyResult, setSaveGrokApiKeyResult] = useState('');
const [error, setError] = useState('');

const encodedApiKey = encodeToBase64(apiKey);
const encodedXiApiKey = encodeToBase64(xiApiKey);
const encodedGrokApiKey = encodeToBase64(grokApiKey);

const saveApiKey = async () => {
if (apiKey.length !== 164) {
alert('정확한 api key를 입력하세요.');
return;
}

try {
const saveResponse = await axios.post(`${process.env.REACT_APP_API_URL}/saveApiKey`, {
uuid: uuid,
apiKey: encodedApiKey,
}, {
headers: {
'Content-Type': 'application/json',
}
});
const result = saveResponse.data;
setSaveApiKeyResult(result);
setApiKey('');
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
}
};

const saveXiApiKey = async () => {
if (xiApiKey.length < 32) {
alert('정확한 api key를 입력하세요.');
return;
}

try {
const saveResponse = await axios.post(`${process.env.REACT_APP_API_URL}/saveXiApiKey`, {
uuid: uuid,
xiApiKey: encodedXiApiKey
}, {
headers: {
'Content-Type': 'application/json',
}
});
const result = saveResponse.data;
setSaveXiApiKeyResult(result);
setXiApiKey('');
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
}
};

const saveGrokApiKey = async () => {
if (grokApiKey.length < 84) {
alert('정확한 api key를 입력하세요.');
return;
}

try {
const saveResponse = await axios.post(`${process.env.REACT_APP_API_URL}/saveGrokApiKey`, {
uuid: uuid,
grokApiKey: encodedGrokApiKey
}, {
headers: {
'Content-Type': 'application/json',
}
});
const result = saveResponse.data;
setSaveGrokApiKeyResult(result);
setGrokApiKey('');
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
}
};

return (
<>
<Header title={title} />

<h1>{title}</h1>
<p>openai api key를 입력하세요.</p>
<p>openai api key</p>
<input
type="password"
placeholder="openai api key"
value={apiKey}
onChange={(e) => setApiKey(e.target.value)}
/>
<br/><br/>

<button onClick={saveApiKey}>저장하기</button>
<br/><br/>

{saveApiKeyResult}
<br/><br/>

<p>labs api key를 입력하세요.</p>
<p>labs api key</p>
<input
type="password"
placeholder="labs api key"
value={xiApiKey}
onChange={(e) => setXiApiKey(e.target.value)}
/>
<br/><br/>

<button onClick={saveXiApiKey}>저장하기</button>
<br/><br/>

{saveXiApiKeyResult}
<br/><br/>

<p>xai api key를 입력하세요.</p>
<p>xai api key</p>
<input
type="password"
placeholder="xai api key"
value={xiApiKey}
onChange={(e) => setGrokApiKey(e.target.value)}
/>
<br/><br/>

<button onClick={saveGrokApiKey}>저장하기</button>
<br/><br/>

{saveGrokApiKeyResult}
<br/>

{error && (
<div className="error">
{error}
</div>
)}
</>
);
}

export default Auth;
