import React, { useState } from 'react';

interface FileSendButtonProps {
title: string;
roomType: string;
file: File | null;
setFile: React.Dispatch<React.SetStateAction<File | null>>;
setSource: React.Dispatch<React.SetStateAction<File | null>>;
setOutput: React.Dispatch<React.SetStateAction<React.ReactNode>>;
isSending: boolean;
setIsSending: React.Dispatch<React.SetStateAction<boolean>>;
setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
setTransDataLoad: React.Dispatch<React.SetStateAction<boolean>>;
}

const FileSendButton: React.FC<FileSendButtonProps> = ({
title,
roomType,
file,
setFile,
setSource,
setOutput,
isSending,
setIsSending,
setCurrentPage,
setSearchQuery,
setTransDataLoad
}) => {
const [inputKey, setInputKey] = useState<number>(Date.now());

const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
const selectedFile = event.target.files ? event.target.files[0] : null;
if (selectedFile) {
const allowedExtensions = /(\.flac|\.mp3|\.mp4|\.mpeg|\.mpga|\.m4a|\.ogg|\.wav|\.webm)$/i;
if (!allowedExtensions.exec(selectedFile.name)) {
alert('허용되는 파일 형식은 .flac, .mp3, .mp4, .mpeg, .mpga, .m4a, .ogg, .wav, .webm입니다.');
setInputKey(Date.now());
return;
}
setFile(selectedFile);
}
};

const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
e.preventDefault();

if (!file) {
alert(`${roomType === 'transcript' ? "변환할" : "번역할"} 파일을 선택하세요.`);
return;
}

setIsSending(true);
setOutput('');
setCurrentPage(1);
setSearchQuery('');
setTransDataLoad(true);
setSource(file);
setInputKey(Date.now());
};

return (
<>
<h1>{title}</h1>
<p>{roomType === 'transcript' ? "변환할 파일을 선택하세요." : "번역할 파일을 선택하세요."}</p>
<form onSubmit={submitHandler}>
<input type="file" key={inputKey} onChange={fileChangeHandler} />
<p>{file ? file.name : "파일이 선택되지 않았습니다."}</p>
<br /><br />
<button type="submit" disabled={isSending}>{roomType === 'transcript' ? "텍스트 변환" : "번역"}</button>
</form>
<br />
</>
);
}

export default FileSendButton;
