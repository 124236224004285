import React, { useEffect, useState, FC } from 'react';
import axios from 'axios';

import { axiosErrorHandler } from '../common/error';
import createChatLog from './createchatlog';
import createUsed from './createused';

import { Messages } from '../../@types/messages';

interface ChatDisplayProps {
roomType: string;
chatLog: React.ReactNode;
setChatLog: React.Dispatch<React.SetStateAction<React.ReactNode>>;
used: React.ReactNode;
setUsed: React.Dispatch<React.SetStateAction<React.ReactNode>>;
model: string;
messages: Messages[];
setMessages: React.Dispatch<React.SetStateAction<Messages[]>>;
userInput: string;
setUserInput: React.Dispatch<React.SetStateAction<string>>;
setFile: React.Dispatch<React.SetStateAction<File | null>>;
saveResult: string;
setSaveResult: React.Dispatch<React.SetStateAction<string>>;
setDeleteResults: React.Dispatch<React.SetStateAction<string>>;
setIsSending: React.Dispatch<React.SetStateAction<boolean>>;
chatDataLoad: boolean;
grokApiKey: string;
akNo: number;
}

const ChatDisplay: FC<ChatDisplayProps> = ({
roomType,
chatLog,
setChatLog,
used,
setUsed,
model,
messages,
setMessages,
userInput,
setUserInput,
setFile,
saveResult,
setSaveResult,
setDeleteResults,
setIsSending,
chatDataLoad,
grokApiKey,
akNo
}) => {
const [error, setError] = useState<string>('');

useEffect(() => {
const SendRequest = async () => {
setDeleteResults('');
setUsed('');
setSaveResult('');
setChatLog('잠시만 기다려 주세요.');

let data = {
messages: messages,
model: model,
stream: false,
temperature: roomType === 'chatgrok' ? 0.5 : 0.9,
top_p: roomType === 'chatgrok' ? 0.5 : 0.9,
frequency_penalty: roomType === 'chatgrok' ? 1.0 : 2.0,
presence_penalty: roomType === 'chatgrok' ? 1.0 : 1.5,
};

const config = {
headers: {
Authorization: `Bearer ${grokApiKey}`,
'Content-Type': 'application/json',
},
};
let botResponse: string;
let promptTokens: number;
let completionTokens: number;
let totalTokens: number;

try {
const response = await axios.post('https://api.x.ai/v1/chat/completions', data, config);
botResponse = response.data.choices[0].message.content;
promptTokens = response.data.usage.prompt_tokens;
completionTokens = response.data.usage.completion_tokens;
totalTokens = response.data.usage.total_tokens;
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
setUserInput('');
setChatLog('');
setIsSending(false);
return;
}

try {
const saveResponse = await axios.post(`${process.env.REACT_APP_API_URL}/saveChat`, {
userInput: userInput,
botResponse: botResponse,
roomType: roomType,
akNo: akNo
}, {
headers: {
'Content-Type': 'application/json'
}
});
const result = saveResponse.data;
setSaveResult(result);
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
setUserInput('');
setChatLog('');
setIsSending(false);
return;
}

setChatLog(createChatLog(roomType, userInput, botResponse));
setUsed(createUsed(promptTokens, completionTokens, totalTokens));
setMessages([...messages, { role: 'assistant', content: botResponse }]);
setUserInput('');
setFile(null);
setIsSending(false);
};

if (messages[messages.length - 1]?.role === 'user' && !chatDataLoad && !error) {
SendRequest();
}
}, [roomType, setChatLog, setUsed, model, messages, setMessages, userInput, setUserInput, setFile, setSaveResult, setDeleteResults, setIsSending, chatDataLoad, grokApiKey, akNo, error, setError]);

return (
<>
<div aria-live="polite">
{chatLog}
</div>
{used}
{error && (
<div className="error" aria-live="polite">
{error}
<br /><br />
</div>
)}
{saveResult}
</>
);
};

export default ChatDisplay;
