import { useEffect, useState, FC } from 'react';
import axios from 'axios';

import { axiosErrorHandler } from '../common/error';

interface ChatDatasDeleterProps {
setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
deleteTargets: string;
setDeleteTargets: React.Dispatch<React.SetStateAction<string>>;
deleteResults: string;
setDeleteResults: React.Dispatch<React.SetStateAction<string>>;
akNo: number;
}

const ChatDatasDeleter: FC<ChatDatasDeleterProps> = ({
setCurrentPage,
deleteTargets,
setDeleteTargets,
deleteResults,
setDeleteResults,
akNo
}) => {
const [error, setError] = useState<string>('');

useEffect(() => {
const deleteDatas = async () => {
try {
const response = await axios.post(`${process.env.REACT_APP_API_URL}/deleteChatAudioDatas`,
{
roomType: deleteTargets,
akNo: akNo
},
{
headers: {
'Content-Type': 'application/json',
},
});
const deleteResponse = response.data;
setDeleteResults(deleteResponse);
setCurrentPage(1);
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
}
};

if (deleteTargets) {
deleteDatas().then(() => {
setDeleteTargets('');
});
}
}, [setCurrentPage, deleteTargets, setDeleteTargets, setDeleteResults, akNo, setError]);

return (
<>
<div aria-live="polite">
{deleteResults}
</div>
{error && (
<div className="error" aria-live="polite">
{error}
<br /><br />
</div>
)}
</>
);
};

export default ChatDatasDeleter;
