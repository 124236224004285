import React from 'react';

import { ChatAudioData } from '../../@types/data';

interface ChatDatasDeleteButtonProps {
setDeleteTargets: React.Dispatch<React.SetStateAction<string>>;
roomType: string;
chatAudioData: ChatAudioData[];
}

const ChatDatasDeleteButton: React.FC<ChatDatasDeleteButtonProps> = ({
setDeleteTargets,
roomType,
chatAudioData
}) => {
const deleteButtonHandler = () => {
setDeleteTargets(roomType);
};

return (
<>
{chatAudioData.length > 0 && (
<>
<button onClick={deleteButtonHandler}>전체 삭제</button>
<br/><br/>
</>
)}
</>
);
};

export default ChatDatasDeleteButton;
